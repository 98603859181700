import * as React from "react"
import PageLayout from "../../components/pageLayout";
import {GatsbyImage} from "gatsby-plugin-image";
import {graphql, useStaticQuery} from "gatsby";
import Seo from "../../components/seo";

const InspeksjonPage = () => {
    const {
        kraftlinje,
        rorgate,
        vindmolle,
        bygninger,
        // data
    } = useStaticQuery(graphql`
        {
            kraftlinje: file(relativePath: { eq: "inspeksjon/linjeinspeksjon.jpg" }) {
                childImageSharp {
                    gatsbyImageData
                }
            },
            rorgate: file(relativePath: { eq: "inspeksjon/rorgate.jpg" }) {
                childImageSharp {
                    gatsbyImageData
                }
            },
            vindmolle: file(relativePath: { eq: "inspeksjon/vindmolle.jpeg" }) {
                childImageSharp {
                    gatsbyImageData
                }
            },
            bygninger: file(relativePath: { eq: "inspeksjon/bygninger.jpeg" }) {
                childImageSharp {
                    gatsbyImageData
                }
            },
#            data: markdownRemark(frontmatter: {slug: {eq: "bransjer/landbruk"}}) {
#                id
#                frontmatter {
#                    subTitle
#                    title
#                    bruksomrader
#                    girdegbedre
#                }
#                html
#            },
        }
    `)

    return <PageLayout>
        <Seo title={"Inspeksjon"}/>
        <div className="relative py-16 bg-secondary-light overflow-hidden">
            <div className="relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose mx-auto">
                    <h1>
            <span className="block text-base text-center text-primary font-semibold tracking-wide uppercase">
              Tjenester:
            </span>
                        <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-100 sm:text-4xl">
              Inspeksjon
            </span>
                    </h1>
                </div>
                <div className="mt-6 prose prose-primary prose-lg text-gray-200 mx-auto">
                    <p>
                        Romvesen utfører inspeksjon på ulike typer anlegg. Vi benytter ulike drone- og inspeksjonssystemer alt etter hva oppdraget gjelder. Vi inngår her ofte flerårige avtaler med inspeksjoner som gjentas med avtalte intervaller. <br /> Eksempler på inspeksjon er:
                    </p>
                    {/*<ul>*/}
                    {/*    <li><strong>Kraftlinjer:</strong> Feil og mangler kan enkelt identifiseres og varmgang oppdages. Vi utfører inspeksjoner på alle typer høyspent anlegg og leverer georeferert dokumentasjon for enkel integrasjon i kundens eksisterende system. Våre løsninger eliminerer risiko for personell og behovet for utkopling.</li>*/}
                    {/*    <li><strong>Vindturbiner:</strong> Også her identifiseres feil og mangler. Både ytre skader og indre strukturelle skader kan avdekkes på hele vindturbinen.</li>*/}
                    {/*    <li><strong>Rørgater:</strong> Vi utfører visuell og thermografisk inspeksjon av rørgater. Der tilgang er et problem, kutter dette ned tid og kostnader, samt risiko for personell.</li>*/}
                    {/*    <li><strong>Bygninger og eiendom:</strong> Bruk av våre dronesystemer gir en rask og effektiv inspeksjon av bygninger og eiendom og er mer nøyaktig enn vanlig manuell inspeksjon. Oppdag raskt skader på konstruksjon, varmetap, o.l., og få satt gang kostnadsreduserende tiltak. </li>*/}
                    {/*</ul>*/}
                </div>
            </div>
        </div>
        <div className="relative bg-secondary-light pt-8 pb-32 overflow-hidden">
            <div className="relative">
                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                    <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0">
                        <div>
                            <div className="mt-6">
                                <h2 className="text-3xl font-extrabold tracking-tight text-gray-100">
                                    Kraftlinjer
                                </h2>
                                <p className="mt-4 text-lg text-gray-200">
                                    Feil og mangler kan enkelt identifiseres og varmgang oppdages. Vi utfører inspeksjoner på alle typer høyspent anlegg og leverer georeferert dokumentasjon for enkel integrasjon i kundens eksisterende system. Våre løsninger eliminerer risiko for personell og behovet for utkopling.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 sm:mt-16 lg:mt-0">
                        <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                            <GatsbyImage
                                className="w-full rounded-xl max-h-72 lg:max-h-full lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                                image={kraftlinje.childImageSharp.gatsbyImageData}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-24">
                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                    <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                        <div>
                            <div className="mt-6">
                                <h2 className="text-3xl font-extrabold tracking-tight text-gray-100">
                                    Vindturbiner
                                </h2>
                                <p className="mt-4 text-lg text-gray-200">
                                    Også her identifiseres feil og mangler. Både ytre skader og indre strukturelle skader kan avdekkes på hele vindturbinen.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                        <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                            <GatsbyImage
                                className="w-full rounded-xl max-h-72 lg:max-h-full lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                                image={vindmolle.childImageSharp.gatsbyImageData}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative mt-24">
                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                    <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0">
                        <div>
                            <div className="mt-6">
                                <h2 className="text-3xl font-extrabold tracking-tight text-gray-100">
                                    Rørgater
                                </h2>
                                <p className="mt-4 text-lg text-gray-200">
                                    Vi utfører visuell og thermografisk inspeksjon av rørgater. Der tilgang er et problem, kutter dette ned tid og kostnader, samt risiko for personell.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 sm:mt-16 lg:mt-0">
                        <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                            <GatsbyImage
                                className="w-full rounded-xl max-h-72 lg:max-h-full lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                                image={rorgate.childImageSharp.gatsbyImageData}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-24">
                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                    <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                        <div>
                            <div className="mt-6">
                                <h2 className="text-3xl font-extrabold tracking-tight text-gray-100">
                                    Bygninger og eiendom
                                </h2>
                                <p className="mt-4 text-lg text-gray-200">
                                    Bruk av våre dronesystemer gir en rask og effektiv inspeksjon av bygninger og eiendom og er mer nøyaktig enn vanlig manuell inspeksjon. Oppdag raskt skader på konstruksjon, varmetap, o.l., og få satt gang kostnadsreduserende tiltak.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                        <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                            <GatsbyImage
                                className="w-full rounded-xl max-h-72 lg:max-h-full lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                                image={bygninger.childImageSharp.gatsbyImageData}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </PageLayout>
}

export default InspeksjonPage